import $ from 'jquery'

$('#detail-slider').slick({
  dots: false,
  infinite: true,
  slidesToShow: 1,
  slidesToScroll: 1,
  speed: 300,
  nextArrow: '.detail--right',
  prevArrow: '.detail--left',
})